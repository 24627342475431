import React from 'react'
import { Image } from '@shared/components/image'
import { useSelectDevice } from '@shared/hooks/use-select-device'
import GoogleLogo from '@shared/svg/GoogleLogo.svg'
import YandexLogo from '@shared/svg/YandexLogo.svg'
import styled from 'styled-components'

export const ReviewLinksBlock = (): JSX.Element => (
	<ViewRowRoot>
		<TextTitle>Больше отзывов</TextTitle>
		<ViewRowImages>
			<LinkImage
				href={`https://yandex.ru/maps/org/credit_club/1395826355/reviews/?ll=60.614272%2C56.836066&z=13`}
				target='_blank'
				rel='noopener noreferrer'
			>
				<ImageYandex />
			</LinkImage>
			<LinkImage
				href={`https://ekaterinburg.flamp.ru/firm/credit_club_ooo_magazin_kreditov-70000001028603120`}
				target='_blank'
				rel='noopener noreferrer'
			>
				<ImageFlamp
					name={useSelectDevice({
						default: 'FlampImage.png',
					})}
					alt='flamp'
				/>
			</LinkImage>
			<LinkImage
				href={`https://www.google.com/maps/place/Credit.Club/@56.836118,60.6123154,17z/data=!3m1!4b1!4m5!3m4!1s0x43c16d34f068d821:0xab8dd2b7093cfe9f!8m2!3d56.836118!4d60.6145041`}
				target='_blank'
				rel='noopener noreferrer'
			>
				<ImageGoogle />
			</LinkImage>
		</ViewRowImages>
	</ViewRowRoot>
)

const ImageGoogle = styled(GoogleLogo)({
	height: 32,
	width: 'auto',
})

const ImageFlamp = styled(Image)({
	height: 32,
	width: 127,
})

const ImageYandex = styled(YandexLogo)({
	height: 32,
	width: 'auto',
})

const LinkImage = styled.a({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
})

const ViewRowImages = styled.div(({ theme }) => ({
	flex: 1,
	display: 'flex',
	gap: '20px 40px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	[theme.mediaQueries.mediaMinWidthMobile]: {
		flexDirection: 'row',
		alignItems: 'center',
	},
}))

const TextTitle = styled.h3(({ theme }) => ({
	whiteSpace: 'nowrap',
	...theme.typography.text20_26,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		...theme.typography.text24_32,
	},
}))

const ViewRowRoot = styled.div(({ theme }) => ({
	display: 'flex',
	gap: '20px 40px',
	flexDirection: 'column',
	alignItems: 'left',
	marginTop: 40,
	[theme.mediaQueries.mediaMinWidthMobile]: {
		flexDirection: 'row',
		alignItems: 'center',
	},
}))
