import React, { ReactNode, useCallback, useState } from 'react'
import { Button } from '@shared/components/button'
import { Image } from '@shared/components/image'
import { Section } from '@shared/components/section'
import clsx from 'clsx'

import { feedbackData } from './data'
import { ReviewLinksBlock } from './ReviewLinksBlock'

import styles from './style.module.scss'

export interface FeedbackProps {
	title: ReactNode
	subtitle?: ReactNode
	cols?: 2 | 3
	data?: {
		name: string
		avatarUrl?: string
		href?: string
		position?: ReactNode
		shortText?: ReactNode
		text?: ReactNode
	}[]
	withReviewLinks?: boolean
}

export const View = ({
	title,
	subtitle,
	cols = 3,
	data = feedbackData,
	withReviewLinks,
}: FeedbackProps) => {
	const [isShowMore, setShowMore] = useState(false)
	const [activeFeedbacks, setActiveFeedbacks] = useState<number[]>([])

	const countShowFeedbacks = cols

	const showMore = useCallback(() => setShowMore(true), [])

	const showFullFeedback = useCallback(
		(index) => setActiveFeedbacks([...activeFeedbacks, index]),
		[activeFeedbacks]
	)

	return (
		<Section className={styles.wrapper}>
			<h2 style={{ whiteSpace: 'pre-wrap' }}>{title}</h2>
			{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
			{withReviewLinks && <ReviewLinksBlock />}
			<div className={styles.cards}>
				{data.map(({ name, avatarUrl, href, position, shortText, text }, index) => {
					if (index + 1 > countShowFeedbacks && !isShowMore) return null

					return (
						<div
							className={clsx(styles.cardStyles, cols === 3 ? styles.card : styles.twoColsCard)}
							key={name}
						>
							<div className={styles.cardHeader}>
								{avatarUrl && (
									<div className={styles.avatar}>
										<Image name={avatarUrl} lazy />
									</div>
								)}
								<div className={styles.aboutPerson}>
									<span>{name}</span>
									{position && <div className={styles.position}>{position}</div>}
								</div>
							</div>
							<div className={styles.cardContent}>
								{shortText && !activeFeedbacks.includes(index) ? (
									<>
										{shortText}...
										<div className={styles.showMore}>
											<a
												className='main-link'
												{...(href
													? { href, target: '_blank', rel: 'noopener noreferrer' }
													: { onClick: () => showFullFeedback(index) })}
											>
												Показать полностью
											</a>
										</div>
									</>
								) : (
									text
								)}
							</div>
						</div>
					)
				})}
			</div>
			{!isShowMore && (data ?? feedbackData).length > countShowFeedbacks && (
				<div className={styles.actions}>
					<div />
					<div>
						<Button variant='outlined' className={styles.button} onClick={showMore}>
							Показать больше
						</Button>
					</div>
					<div />
				</div>
			)}
		</Section>
	)
}
